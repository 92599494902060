
@-ms-viewport {
  width: device-width;
}

* {
  box-sizing: border-box !important;

        scrollbar-width: none;
}

html {
  
  height: 100%;
  width: 100%;
  // min-height: 320px;

  .body {

    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;

    .root {

      position: relative;

      height: 100%;
      width: 100%;

      margin: 0;
      padding: 0;

      position: absolute;

      // border: 1px solid white;
      // overflow-x: hidden;
      // overflow-y: scroll;

      // .section_header {

      //   margin: 24px 0;
      //   border-bottom: 1px solid _palette(primary_color);

      //   display: flex;
      //   flex-flow: row nowrap;
      //   justify-content: space-between;
      //   align-items: center;

      //   .section_title {

      //     color: _palette(primary_color);
      //     text-transform: capitalize;

      //   }

      // }

    }
  }
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: #FFFFFF;
}
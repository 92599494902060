
/* Sections View */ 

.sections {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }
  overflow: hidden;

  .sections_frame {

    width: 100%;
    height: 100%;
    
  }

  .sections_content {

    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    .sections_image {

      position: relative;
      width: 100%;

      flex-grow: 2;

      @include breakpoint(medium) {
        display: none;
      }

      .sections_image_wrapper {

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        .sections_background {

          height: 100%;
          min-height: 240px;
          // max-width: 50%;

          margin: 0;
          padding: 0;
          display: block;
          margin-left: auto;

        }

      }

    }

    .sections_form {

      // min-width: 540px;
      // height: auto;

      // margin-bottom: 4rem;

      // flex-grow: 2;

      // border: 1px solid lightsalmon;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

      @include breakpoint(large) {
        margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
      }

      @include breakpoint(medium) {
        // max-width: 420px;
        margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
      }

      @include breakpoint(small) {
        max-width: calc(100% - 2rem);
        margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
      }

      @include breakpoint(xsmall) {
        margin-top: 0;
      }

      .sections_button_wrapper {

        width: 100%;
        height: 4rem;
        margin: 0.5rem 2rem;

        display: flex;
        justify-content: center;

        text-align: center;
        align-items: center;
        border-radius: 2em;

        background: _palette(primary_color);

        @include breakpoint(large) {
          height: 3.5rem;
        }

        &:hover {
          cursor: pointer;
        }

        .sections_button {

          width: 100%;
          font-size: 1.5rem;
          font-weight: _font(regular);
          color: _palette(white);

          @include breakpoint(large) {
            font-size: 1.25rem;
          }

          @include breakpoint(medium) {
            font-weight: _font(light);
          }

        }

      }

    }

  }

  .sections_content_empresarial{
    background-image: url('../../assets/Assets_Empresarial/back_1_01.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;

    .container_empresarial{
      background-image: url('../../assets/Assets_Empresarial/back_1_02.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: row;
    }

    .img_empresarial{
      width: 60%;
      height: auto;
    }

    .logo_container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .logo_empresarial{
        width: 60%;
        height: auto;
        margin-bottom: 4rem;
      }

      .link_card_mng{
        .button_card_mng{
          width: 60%;
          height: auto;
          margin-top: 4rem;
        }
      }
    }
  }

}


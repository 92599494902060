$connect-blue: #3a64a2;

.connect_container {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: url('../../images/connect_module_BG.png');
    background-size: cover;
    background-repeat: repeat-x;
    // position: relative;
    @media screen and (max-width: 1330px){
        justify-content: center;
    }

}

.connect_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 12rem;
    margin-bottom: 4rem;
    margin-right: 2rem;
    margin-top: 0rem;

    @media screen and (max-width: 1330px){
        margin-right: 0;
        margin-left: 0;
    }
}

.connect_logo {
    width: 550px;
    // margin-top: -5%;
    margin-bottom: 5rem;
    @media screen and (max-width: 600px){
        margin: 0;
        margin-bottom: 5rem;
        width: 100%;
    }
}

.connect_main_img {
    // position: relative;
    // float: left;
    // left: 0;
    // bottom: 0;
    margin: 0;
    margin-bottom: -1%;
    margin-left: -.5%;
    width: 70rem;
    overflow: visible;
    align-self: flex-end;

    @media screen and (max-width: 1330px){
        display: none;
    }

    @media screen and (max-width: 1541px) and (min-width: 1300px){
        width: 40em;
    }
}


.connect_button_wrapper {
    width: 100%;
    height: 4rem;
    margin: 0.5rem 2rem;

    display: flex;
    justify-content: center;

    text-align: center;
    align-items: center;
    border-radius: 2em;

    background: $connect-blue;

    @include breakpoint(large) {
        height: 3.5rem;
    }

    &:hover {
        cursor: pointer;
    }

    .connect_button {
        width: 100%;
        font-size: 1.5rem;
        font-weight: _font(regular);
        color: _palette(white);

        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }
    }
}

//Ticket car modules//
.connect_modules_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 100px;
    background: url('../../images/connect_module_BG.png');
    background-size: cover;
    overflow: auto;
    width: 100%;

    .connect_module_logo_container{
        position: absolute;
        right: 0;
        bottom: 125px;

        width: 100px;
        height: 100px;
        // border: 1px solid black;

        margin-right: 10px;
        margin-bottom: 10px;
        
        // @media screen and (max-width: 700px){
        //     left: 50%;
        // }

        @include breakpoint(medium) {
            bottom: 0;
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

}



.connect_header {

    min-height: 2.8em;
    height: 2.4em;
    width: 40rem;

    padding: 1em;
    margin-bottom: 1em;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2em;
    background: $connect-blue;

    @media screen and (max-width: 700px){
        width: 400px;;
    }




    .connect_title {
        font-size: 1.3rem;
        font-weight: _font(regular);
        color: _palette(white);
        text-align: center;
        
        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }

    }

}

.modules_list {
    width: 100%;
    height: 100%;

    .modules_item {
        padding: 0.5em 1em;
        border-bottom: 1px solid _palette(turquoise);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .modules_item_link {
            color: $connect-blue;
            font-weight: _font(light);
            text-align: left;
            flex-grow: 2;
            margin-right: 1em;
        }

        .modules_item_check {
            width: 24px;
            height: 24px;
        }
    }
}

.section_content_connect {

    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: url('../../images/connect_module_BG.png');    background-size: cover;
    overflow: hidden;

    // border: 1px solid yellow;
    .section_element_container {
      display: flex;
      width: 100%;
      /*min-height: 2000px;*/
      justify-content: space-between;
      @include breakpoint(large) {
          width: 80%;
          justify-content: center;
          }

      .dummy{
        width: 50%;
        @include breakpoint(large) {
          display: none;
          }
      }
      .section_splash_wrapper{
        position:absolute;
        // display: inline-block;
        bottom:0;
        left: 0;
        margin-bottom: -25px;
        margin-top: 20%;
        width: 50%;
        // height: 80rem;

        @include breakpoint(large) {
          display: none;
          }

        .section_splash_image_wrapper {
          width: 100%;
          img {
            width: inherit;
          }
        }
      }

      .hidden_wrapper{
        display: none;
      }

      .section_form_container {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 40%;
        margin-right: 5%;
        /*text-align: center;*/


        @include breakpoint(large) {
          margin: auto;
          }

        .section_right_wrapper{
          // border: 1px solid black;
          // width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // margin-left: 40rem; 

          .section_image_wrapper {
            display: content;
            margin-bottom: 4rem;
            // max-width: 500px;
            @include breakpoint(large) {
              margin-top: 1vw;
              // min-width: 40vw;
              width: 100%
              // max-width: 380px;
              // width: 10px;
            }
            img{
                width: inherit;
            }
          }  
          
          .section_form {

            // width: 360px;
            // height: 100%;

            // margin-bottom: 4rem;

            // @include breakpoint(small) {
            //   flex-grow: 2;
            // }

            flex-grow: 2;

            display: content;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            // margin: 0 auto;

            // border: 1px solid lightskyblue;

            // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

            // @include breakpoint(large) {
            //   width: 280px;
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 0;
            // }

            .section_button_wrapper {

              // width: 35rem;
              width: 100%;
              min-width: 500px;
              height: 4.5rem;
              // margin: 1rem;4

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;

              background: #3a64a2;
              margin-bottom: 2rem;

              @include breakpoint(large) {
                height: 3.5rem;
                margin-bottom: 2rem;
                width: 100%;
                // margin: 0.5rem;
              }


              .section_button {

                width: 100%;
                font-size: 1.5rem;
                font-weight: _font(regular);
                color: _palette(white);
                padding-left: 20px;
                padding-right: 20px;
                height: 100%;

                @include breakpoint(large) {
                  padding: 10px;
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }

                display: flex;
                align-items: center; 
                justify-content: center;

                img{
                    max-height: 2.5rem;
                }

               // .scann_button{
               //      max-width: 100%;
               //      max-height: 100%;
               // }

              }

            }

            .section_button_wrapper.connect_button_link{
                  background: #ffffff;
                  border: 2.5px solid #3a64a2;
            }

          }
        }
      }
      .centered_form{
        margin: auto;
      }

    }
  }


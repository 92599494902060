/* Main View */

$canvas_wrapper_color: #edffff;

.main {
  // position: relative;

  width: 100%;
  height: calc(100% - #{_size(header_height_large)});

  margin: 0;
  margin-top: _size(header_height_large);

  display: flex;
  flex-flow: row nowrap;

  // border: 2px solid red;
  // margin: 0;
  // padding: 0;

  // overflow: initial;

  @include breakpoint(medium) {
    height: calc(100% - #{_size(header_height_medium)});
    margin-top: _size(header_height_medium);
  }

  @include breakpoint(small) {
    height: calc(100% - #{_size(header_height_small)});
    margin-top: _size(header_height_small);
  }

  .main_frame {
    width: 100%;
    height: 100%;
  }

  .main_content {
    width: 100%;
    height: 100%;

    .canvas_wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      background: $canvas_wrapper_color;
      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;

      // border: 2px solid yellow;

      .canvas {
        position: relative;
        max-width: 100%;
        max-height: 100%;

        .button {
          background: red;
        }
      }

      .buttons_wrapper {
        position: absolute;
        height: calc(100vh - #{_size(header_height_large)});
        width: calc((100vh - #{_size(header_height_large)}) * 1.77777);
        max-width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;

        // border: 1px solid black;

        .buttons {
          position: relative;
          background-size: cover;
          height: 56.25vw;
          width: 100%;
          margin: 0 auto;
          max-height: calc(100vh - #{_size(header_height_large)});

          // border: 1px solid tomato;

          .button {
            position: absolute;
            top: 0;
            left: 0;
            width: 10%;
            height: 10%;
            border-radius: 10px;

            border: none;
            // border: 2px solid rebeccapurple;

            &:hover {
              cursor: pointer;
            }

            &:focus {
              outline: none;
            }

            &.button_01 {
              top: 8.2%;
              left: 61.5%;
              width: 15.5%;
              height: 9%;
              background: none;
            }

            &.button_02 {
              top: 21%;
              left: 16.75%;
              width: 15.5%;
              height: 9%;
              background: none;
            }

            //section here
            &.button_03 {
              top: 60%;
              left: 50%;
              width: 15.4%;
              height: 10.1%;
              background-image: url("../../images/ic_mantenimiento.png");
              background-size: contain;
              background-color: transparent;
              background-repeat: no-repeat;
            }

            &.button_04 {
              top: 40%;
              left: 30%;
              width: 14.5%;
              height: 9.5%;
              background: none;
            }

            &.button_05 {
              top: 55.5%;
              left: 47%;
              width: 16.5%;
              height: 9.5%;
              background: none;
            }

            &.button_06 {
              top: 51.25%;
              left: 75%;
              width: 15.5%;
              height: 9%;
              background: none;
              border-radius: 5px;
            }

            &.button_07 {
              top: 18.5%;
              left: 47%;
              width: 14.5%;
              height: 9.5%;
              background: none;
            }
          }
        }
      }

      .map_background {
        // height: 48vw;
        // width: 100vw;
        border: 1px solid red;
      }

      .hidden {
        display: none;
      }
    }
  }
}

.feedback_holder {
  width: 80vw;
  max-width: 500px;
  min-width: 370px;

  height: 80vh;
  max-height: 900px;
  min-height: 600px;

  border-radius: 15px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  background-image: url("../../images/feedback_background.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 95%;

  .feedback_element {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .splash_holder {
    margin-top: 10px;
    margin-bottom: 0px;
    max-width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;

    #ered_logo {
      width: 50%;
      margin-bottom: 20px;
    }

    #opinion {
      width: 80%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 80%;
    height: 90%;

    align-items: center;
    // justify-content: center;

    .rating_holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 60px;
      margin-top: 50px;
      margin-bottom: 45px;

      @include breakpoint(medium) {
        margin-bottom: 30px;
      }

      div {
        .react-stars {
          overflow: visible !important; // sorry

          span {
            overflow: visible !important; //sorry
          }
        }
      }
    }

    .submited_holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      width: 100%;
      line-height: 1;

      .submited_flavor {
        font-size: 30pt;
      }
    }

    .comment_holder {
      max-width: 620px;
      height: 100%;
      background-color: transparent;

      .flavor_holder {
        text-align: left;
        .details_flavor {
          margin-bottom: 7px;
          margin-left: 7px;
        }

        .on_error {
          margin-bottom: 10px;
          margin-left: 10px;
          font-weight: bold;
          font-size: 12pt;
          color: #e07269;
          visibility: hidden;
        }

        .highlight_error {
          visibility: visible;
          animation: 0.3s fadeIn;
          animation-fill-mode: forwards;
          font-size: 14pt;
          color: #d52b1e;
        }
      }

      .commentbox {
        border: none;
        overflow: auto;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        height: 100%;
        resize: none;
        border-radius: 15px;
        border: 2px solid #ff5978;
        background-color: rgba(255, 255, 255, 0.75);
        padding: 10px;
      }
    }

    .submit_holder {
      margin-top: 50px;

      button {
        width: 272px;
        height: 50px;
        font-size: 16pt;
        border: 2px solid #ff5978;
        border-radius: 35px;
        transition: 0.3s;
      }

      button:hover {
        cursor: pointer;
      }

      .inactive {
        background-color: #d8d8d8;
      }

      .inactive:hover {
        background-color: #b0b0b0;
        color: black;
      }

      .active {
        background-color: #ff5978;
        color: white;
      }

      .active:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

.feedback_done {
  animation: 0.5s fadeIn;
  animation-fill-mode: backwards;
  animation-delay: 0.3s;
}

.fadeIn {
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
}

.fadeOut {
  animation: fadeOut 0.4s;
  -webkit-animation: fadeOut 0.4s;
  -moz-animation: fadeOut 0.4s;
  -o-animation: fadeOut 0.4s;
  -ms-animation: fadeOut 0.4s;
}

.feedback_modal {
  text-align: right;

  .close_feedback {
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: white;
  }

  .close_feedback:hover {
    cursor: pointer;
  }
}

.modal_pop {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  overflow: hidden;
  @include breakpoint(medium) {
    display: none;
  }
}

.modal_pop:hover {
  opacity: 0.5;
}

.modal_trigger {
  width: 400px;
}

.popup_modal_trigger {
  width: 400px;
  display: none;
}

.popup-overlay {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Ease in secondary */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.ratingstar {
  margin-left: 5px;
  margin-right: 5px;
}

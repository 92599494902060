/* Guest View */

.guest {
  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;
  overflow: hidden;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  .guest_content_ticket {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: rgb(214, 12, 22);
    background: url("../../images/ticket_car_section_BG.png");
    background-size: cover;
    overflow: hidden;

    // border: 1px solid yellow;
    .guest_element_container {
      display: flex;
      width: 100%;
      /*min-height: 2000px;*/
      justify-content: space-between;
      @include breakpoint(large) {
        justify-content: center;
      }

      .guest_splash_wrapper {
        display: inline-block;
        margin-left: -40px;
        width: 80%;
        // height: 80rem;

        @include breakpoint(large) {
          display: none;
        }

        .guest_splash_image_wrapper {
          width: 100%;
          img {
            width: inherit;
          }
        }
      }

      .guest_form_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /*text-align: center;*/

        @include breakpoint(large) {
          margin: auto;
        }

        .guest_right_wrapper {
          margin-top: -20%;
          .guest_image_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            min-width: 80px;
            max-width: 1000px;
            @include breakpoint(large) {
              /*margin: auto;*/
              max-width: 380px;
            }
          }

          .guest_form {
            width: 360px;
            // height: 100%;

            // margin-bottom: 4rem;

            // @include breakpoint(small) {
            //   flex-grow: 2;
            // }

            flex-grow: 2;

            display: content;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            // border: 1px solid lightskyblue;

            // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

            // @include breakpoint(large) {
            //   width: 280px;
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 0;
            // }

            .guest_button_wrapper {
              width: 100%;
              height: 4rem;
              // margin: 1rem;

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;

              background: #333333;

              @include breakpoint(large) {
                height: 3.5rem;
                // margin: 0.5rem;
              }

              &:first-child {
                margin-bottom: 2.5rem;

                @include breakpoint(large) {
                  margin-bottom: 2rem;
                }
              }

              .guest_button {
                width: 100%;
                font-size: 1.5rem;
                font-weight: _font(regular);
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }
          }
        }
      }
    }
  }

  .guest_content_fleet {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: rgb(214, 12, 22);
    background: url("../../images/fleet_module_BG.svg");
    background-size: cover;
    overflow: hidden;

    // border: 1px solid yellow;
    .guest_element_container {
      display: flex;
      width: 100%;
      /*min-height: 2000px;*/
      justify-content: space-between;
      @include breakpoint(large) {
        justify-content: center;
      }

      .guest_splash_wrapper {
        display: inline-block;
        margin-left: -40px;
        width: 80%;
        // height: 80rem;

        @include breakpoint(large) {
          display: none;
        }

        .guest_splash_image_wrapper {
          width: 700px;
          img {
            width: inherit;
          }
        }
      }

      .guest_form_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /*text-align: center;*/

        @include breakpoint(large) {
          margin: auto;
        }

        .guest_right_wrapper {
          margin-top: 0vh;

          .guest_image_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            min-width: 80px;
            max-width: 500px;
            @include breakpoint(large) {
              /*margin: auto;*/
              max-width: 80px;
            }
          }

          .guest_image_wrapper_fleet {
            width: 300px;
            height: 70px;
            margin: 0 auto;
            margin-bottom: 5.5rem;
            min-width: 80px;
            max-width: 500px;
            @include breakpoint(large) {
              /*margin: auto;*/
              max-width: 450px;
            }
          }

          .guest_form {
            width: 360px;
            // height: 100%;

            // margin-bottom: 4rem;

            // @include breakpoint(small) {
            //   flex-grow: 2;
            // }

            flex-grow: 2;

            display: content;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            // border: 1px solid lightskyblue;

            // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

            // @include breakpoint(large) {
            //   width: 280px;
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 0;
            // }

            .guest_button_wrapper {
              width: 100%;
              height: 4rem;
              // margin: 1rem;

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;

              background: #015959;

              @include breakpoint(large) {
                height: 3.5rem;
                // margin: 0.5rem;
              }

              &:first-child {
                margin-bottom: 1.75rem;

                @include breakpoint(large) {
                  margin-bottom: 2rem;
                }
              }

              .guest_button {
                width: 100%;
                font-size: 1.5rem;
                font-weight: _font(regular);
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }

            .guest_button_wrapper_flavor {
              width: 100%;
              height: 4rem;
              // margin: 1rem;

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;
              margin-top: 100px;

              img {
                width: 100%;
                // height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .guest_content_despensas {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: rgb(214, 12, 22);
    background: url("../../images/despensas_module_BG.png");
    background-size: cover;
    overflow: hidden;

    // border: 1px solid yellow;
    .guest_element_container {
      display: flex;
      width: 100%;
      /*min-height: 2000px;*/
      justify-content: space-between;
      @include breakpoint(large) {
        justify-content: center;
      }

      .guest_splash_wrapper {
        display: inline-block;
        margin-left: -40px;
        width: 60%;
        // height: 80rem;

        @include breakpoint(large) {
          display: none;
        }

        .guest_splash_image_wrapper {
          width: 100%;
          img {
            width: inherit;
          }
        }
      }

      .guest_form_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /*text-align: center;*/

        @include breakpoint(large) {
          margin: auto;
        }

        .guest_right_wrapper {
          margin-top: 0%;
          .guest_image_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            width: 500px;
            min-width: 80px;
            max-width: 500px;
            @include breakpoint(large) {
              margin-top: -35%;
              max-width: 350px;
            }
          }

          .guest_form {
            width: 360px;
            // height: 100%;

            // margin-bottom: 4rem;

            // @include breakpoint(small) {
            //   flex-grow: 2;
            // }

            flex-grow: 2;

            display: content;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            // border: 1px solid lightskyblue;

            // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

            // @include breakpoint(large) {
            //   width: 280px;
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 0;
            // }

            .guest_button_wrapper {
              width: 100%;
              height: 4rem;
              // margin: 1rem;

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;

              background: #d52b1e;

              @include breakpoint(large) {
                height: 3.5rem;
                // margin: 0.5rem;
              }

              &:first-child {
                margin-bottom: 2.5rem;

                @include breakpoint(large) {
                  margin-bottom: 2rem;
                }
              }

              .guest_button {
                width: 100%;
                font-size: 1.5rem;
                font-weight: _font(regular);
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }
          }
        }
      }
    }
  }

  .guest_content_empresarial {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: rgb(214, 12, 22);
    background: url("../../images/empresarial_guest_BG.png");
    background-size: cover;
    overflow: hidden;

    @include breakpoint(large) {
      margin: 0 auto;
    }

    // border: 1px solid yellow;
    .guest_element_container {
      display: flex;
      width: 100%;
      margin-top: -100px;
      /*min-height: 2000px;*/
      justify-content: space-between;
      @include breakpoint(large) {
        margin: 0 auto;
        justify-content: center;
      }

      .guest_splash_wrapper {
        display: inline-block;
        // margin-left: 0px;
        margin-right: -2%;
        width: 60%;
        // height: 80rem;

        @include breakpoint(large) {
          display: none;
        }

        .guest_splash_image_wrapper {
          width: 100%;
          img {
            width: inherit;
          }
        }
      }

      .guest_form_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /*text-align: center;*/

        @include breakpoint(large) {
          margin: auto;
        }

        .guest_right_wrapper {
          margin-top: 0%;
          margin-left: 10%;
          @include breakpoint(large) {
            margin: 0 auto;
          }
          .guest_image_wrapper {
            margin: 0 auto;
            margin-bottom: -2rem;
            width: 500px;
            min-width: 80px;
            max-width: 500px;
            @include breakpoint(large) {
              margin-top: -35%;
              max-width: 450px;
            }
          }

          .guest_form {
            width: 360px;
            // height: 100%;

            // margin-bottom: 4rem;

            // @include breakpoint(small) {
            //   flex-grow: 2;
            // }

            flex-grow: 2;

            display: content;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            // border: 1px solid lightskyblue;

            // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

            // @include breakpoint(large) {
            //   width: 280px;
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 0;
            // }

            .guest_button_wrapper {
              width: 100%;
              height: 4rem;
              // margin: 1rem;

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;

              background: #00859b;

              @include breakpoint(large) {
                height: 3.5rem;
                // margin: 0.5rem;
              }

              &:first-child {
                margin-bottom: 2.5rem;

                @include breakpoint(large) {
                  margin-bottom: 2rem;
                }
              }

              .guest_button {
                width: 100%;
                font-size: 1.5rem;
                font-weight: _font(regular);
                color: _palette(white);

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }
          }
        }
      }
    }

    // .guest_bg_asset{
    //   z-index: -10;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   margin-left: -6rem;
    //   margin-bottom: -6rem;

    //   img{
    //     width: 40rem;
    //   }

    //   @include breakpoint(large) {
    //     display: none;
    //   }
    // }
    .guest_bg_asset {
      position: absolute;
      z-index: -10;
      left: -7%;
      top: 64%;
      width: 60%;
      // margin-left: -60rem;
      // margin-top: 4rem;
      // height: 40rem;
      // overflow: hidden;
      img {
        height: 100%;
        width: inherit;
      }
      @include breakpoint(large) {
        display: none;
      }
    }
  }

  // template
  .guest_content_mantenimiento {
    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    background: rgb(214, 12, 22);
    background: url("../../images/mantenimiento_guest_BG.png");
    background-size: cover;
    overflow: hidden;

    // border: 1px solid yellow;
    .guest_element_container {
      display: flex;
      width: 100%;
      /*min-height: 2000px;*/
      justify-content: space-between;
      @include breakpoint(large) {
        justify-content: center;
      }

      .guest_splash_wrapper {
        display: inline-block;
        margin-left: -40px;
        width: 60%;
        // height: 80rem;

        @include breakpoint(large) {
          display: none;
        }

        .guest_splash_image_wrapper {
          width: 100%;
          img {
            position: absolute;
            top: -70px;
            right: -240px;
            width: 900px;
          }
        }
      }

      .guest_form_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /*text-align: center;*/

        @include breakpoint(large) {
          margin: auto;
        }

        .guest_right_wrapper {
          margin-top: 0%;
          .guest_image_wrapper {
            margin: 0 auto;
            margin-bottom: 4rem;
            width: 500px;
            min-width: 80px;
            max-width: 500px;
            @include breakpoint(large) {
              margin-top: -35%;
              max-width: 350px;
            }
          }

          .guest_form {
            width: 360px;
            // height: 100%;

            // margin-bottom: 4rem;

            // @include breakpoint(small) {
            //   flex-grow: 2;
            // }

            flex-grow: 2;

            display: content;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            // border: 1px solid lightskyblue;

            // margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

            // @include breakpoint(large) {
            //   width: 280px;
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 0;
            // }

            .guest_button_wrapper {
              width: 100%;
              height: 4rem;
              // margin: 1rem;

              display: flex;
              justify-content: center;

              text-align: center;
              align-items: center;
              border-radius: 2em;

              background: #a8cc14;

              @include breakpoint(large) {
                height: 3.5rem;
                // margin: 0.5rem;
              }

              &:first-child {
                margin-bottom: 2.5rem;

                @include breakpoint(large) {
                  margin-bottom: 2rem;
                }
              }

              .guest_button {
                width: 100%;
                font-size: 1.5rem;
                font-weight: _font(regular);
                color: #0d374f;

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }
              }
            }
          }
        }
      }
    }
  }
}

$despensas-blue: #162056;

.despensas_container {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: url('../../images/back_1_01.png');
    background-size: cover;
    background-repeat: repeat-x;
    // position: relative;
    @media screen and (max-width: 1330px){
        justify-content: center;
    }

}
.despensas_container_form{
    width:60vw;
    text-align: center;
    background-color:white;
    padding: 36px 168px;
    margin: 6rem 0;
    border-radius:0 100px 0 100px;
    @media screen and (max-width: 768px) {
      margin: 3rem 0;
      padding: 36px;
    }
}

.despensas_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 12rem;
    margin-bottom: 4rem;
    margin-right: 2rem;
    margin-top: 0rem;

    @media screen and (max-width: 1330px){
        margin-right: 0;
        margin-left: 0;
    }
}

.despensas_logo {
    width: 100%;
    max-width: 350px;
    margin: 1.5rem 0;
}

.despensas_main_img {
    // position: relative;
    // float: left;
    // left: 0;
    // bottom: 0;
    margin: 0;
    margin-bottom: -1%;
    margin-left: -.5%;
    width: 70rem;
    overflow: visible;
    align-self: flex-end;

    @media screen and (max-width: 1330px){
        display: none;
    }

    @media screen and (max-width: 1541px) and (min-width: 1300px){
        width: 40em;
    }
}


.despensas_button_wrapper {
    width: 100%;
    height: 4rem;
    margin: 0.5rem 2rem;

    display: flex;
    justify-content: center;

    text-align: center;
    align-items: center;
    border-radius: 2em;

    background: $despensas-blue;

    @include breakpoint(large) {
        height: 3.5rem;
    }

    &:hover {
        cursor: pointer;
    }

    .despensas_button {
        width: 100%;
        font-size: 1.5rem;
        font-weight: _font(regular);
        color: _palette(white);

        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }
    }
}

//Ticket car modules//
.despensas_modules_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../../images/back_1_01.png');
    background-size: cover;
    overflow: auto;
    height: 100vh;
    @media screen and (max-width:756px) {
      justify-content: start;
    }
}

.despensas_header {

    min-height: 4em;
    width: 100%;

    padding: 1em;
    margin-bottom: 1em;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2em;
    background: rgb(169, 141, 206);

    .despensas_title {
        font-size: 1.5rem;
        font-weight: _font(regular);
        color: _palette(white);
        text-align: center;
        
        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }

    }

}

.modules_list {
    width: 100%;
    height: 100%;

    .modules_item {
        padding: 0.5em 1em;
        border-bottom: 1px solid _palette(turquoise);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .modules_item_link {
            color: $despensas-blue;
            font-weight: _font(light);
            text-align: left;
            flex-grow: 2;
            margin-right: 1em;
        }

        .modules_item_check {
            width: 24px;
            height: 24px;
        }
    }
}

.section_content_despensas {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url('../../images/back_1_01.png');    background-size: cover;
    overflow: hidden;
    height: 100vh;
    .section_splash_image_wrapper{
      width: 50%;
    }
    
  }
.section_form_container{
  margin: 3rem auto 0;
  text-align: center;
  .section_button_wrapper{
    margin: 1.5rem 0;
    width: 100%;
  }
  .section_button{
    background-color: rgb(169, 141, 206);
    color: white;
    display: block;
    border-radius: 42px;
    padding: 8px 32px ;
  }
}


/* Intro View */ 

.intro {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  // border: 1px solid red;

  .intro_content {

    width: 100%;
    min-height: 90%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    // border: 2px solid rebeccapurple;

    // min-height: calc(100% - #{_size(header_height_xlarge)});
    // // margin: _size(header_height_xlarge) auto 0 auto;

    // @include breakpoint(large) {
    //   // margin-top: _size(header_height_large);
    //   min-height: calc(100% - #{_size(header_height_large)});
    // }

    // @include breakpoint(medium) {
    //   // margin-top: _size(header_height_medium);
    //   min-height: calc(100% - #{_size(header_height_medium)});
    // }

    // @include breakpoint(small) {
    //   // margin-top: _size(header_height_small);
    //   min-height: calc(100% - #{_size(header_height_small)});
    //   justify-content: center;
    // }

    // @include breakpoint(large) {
    //   justify-content: flex-start;
    // }

    .intro_left, .intro_center, .intro_right {
      // border: 1px solid salmon;
    }

    .intro_left, .intro_right {
      width: 40%;
      width: 800px;

      @include breakpoint(large) {
        display: none;
      }

      .intro_image {
        width: 100%;
      }

    }

    .intro_center {

      flex-grow: 2;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;

      .intro_logo_wrapper, .intro_form, .intro_icons_wrapper, .intro_documents {
        // border: 1px solid lightsalmon;
        // border: 1px solid green;
      }

      .intro_logo_wrapper {

        width: 90%;
        max-width: 500px;
        margin-top: 2rem;

        .intro_logo {
          width: 100%;
        }

      }

      .intro_icons_wrapper {

        display: none;
        margin-bottom: 2rem;

        @include breakpoint(large) {
          display: initial;
        }

        .intro_icons {
          width: 100%;
          // height: 200px;
        }

      }

      .intro_form {

        width: 380px;
        // min-height: 100%;

        margin: 7rem 0 8rem 0;

        @include breakpoint(small) {
          flex-grow: 2;
        }

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        @include breakpoint(large) {
          width: 280px;
        }

        .intro_button_wrapper {

          width: 100%;
          height: 4rem;
          margin: 1rem;

          display: flex;
          justify-content: center;

          text-align: center;
          align-items: center;
          border-radius: 2em;

          background: _palette(primary_color);

          @include breakpoint(large) {
            height: 3.5rem;
            margin: 0.5rem;
          }

          .intro_button {

            width: 100%;
            font-size: 1.5rem;
            font-weight: _font(regular);
            color: _palette(white);

            @include breakpoint(large) {
              font-size: 1.25rem;
            }

            @include breakpoint(medium) {
              font-weight: _font(light);
            }

          }

        }

      }

      .intro_image {

        width: 100%;
        text-align: right;
        height: 40vh;
        min-height: 240px;

        @include breakpoint(small) {
          display: none;
        }

        .intro_background {
          
          // position: absolute;
          // right: 0;
          // bottom: 7em;
          // width: 100%;
          // max-width: 420px;
          height: 100%;

          margin: 0;
          padding: 0;
          display: block;
          margin-left: auto;

          // border: 1px solid lightskyblue;

          // @include breakpoint(large) {
          //   height: 35%;
          // }

          // @include breakpoint(medium) {
          //   height: 30%;
          // }

          // @include breakpoint(small) {
          //   display: none;
          // }

        }
      }

      .intro_documents {

        // position: absolute;
        // bottom: 0;

        width: 320px;
        height: auto;
        padding: 0.25rem 0;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        margin-bottom: 2rem;

        @include breakpoint(large) {
          width: 280px;
        }

        @include breakpoint(small) {
          // align-content: flex-end;
        }

        .intro_document_wrapper {

          width: 100%;
          height: 2rem;
          margin: 0.5rem;

          display: flex;
          justify-content: center;

          text-align: center;
          align-items: center;
          border-radius: 1em;
          border: 2px solid _palette(accent_color);

          background: _palette(white);

          @include breakpoint(large) {
            margin: 0.25rem;
          }

          .intro_document {

            width: 100%;
            font-size: 0.9rem;
            color: _palette(accent_color);

            @include breakpoint(large) {
              font-size: 0.8rem;
            }

            @include breakpoint(medium) {
              font-weight: _font(light);
            }

          }

        }

      }

      .intro_apps_title {
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .intro_apps {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        gap: 10px;

        .intro_app_store {
          width: 140px;
          height: 41px;
          margin: 0;
        }

        .intro_google_play {
          width: 140px;
        }

        .intro_pcSystem {
          width: 100px;
          height: 41px;
        }

        .intro_appGallery {
          height: 41px;
          width: 140px;
        }

        @include breakpoint(small) {
          flex-direction: column;
          margin-bottom: 30px;
        }
      }
    }

  }

}


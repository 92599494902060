
/* Sections View */ 

.thanks {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  .thanks_frame {

    width: 100%;
    height: 100%;
    
  }

  .thanks_content {

    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    .thanks_background {
      
      position: absolute;
      right: 0;
      bottom: 1em;
      height: 60%;

      z-index: -1000;

      @include breakpoint(large) {
        height: 45%;
      }

      @include breakpoint(medium) {
        height: 40%;
      }

      @include breakpoint(small) {
        display: none;
      }

    }

    .thanks_form {

      min-width: 360px;
      height: auto;

      padding: 1rem 2rem;
      margin-right: 20%;
      margin-bottom: 4rem;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: _palette(accent_color);

      border-radius: 2em;

      border: 2px solid _palette(accent_color);

      @include breakpoint(small) {
        margin: 0;
        min-width: auto;
        width: calc(100% - 2rem);
      }

      .thanks_title {

        width: 100%;
        font-size: 3rem;
        font-weight: _font(medium);
        margin: 1rem 0;

      }

      .thanks_subtitle {

        width: 100%;
        font-size: 1.5rem;
        font-weight: _font(light);

      }

    }

  }

}


.mantenimiento_container {
  width: 100vw;
  height: 100vh;
  background: url("../../images/mantenimiento_section_BG.png");
  background-size: cover;
  background-repeat: repeat-x;
  padding-top: 3.9rem;
}

.mantenimiento_container_bg_white {
  overflow: hidden;
  width: 95vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e8fcfc;
  border-top-right-radius: 20% 30%;
  margin-right: 2.5rem;
}

.mantenimiento_form_container {
  position: relative;
  // left: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5vw;
  margin-bottom: 6rem;
  @include breakpoint(large) {
    margin: 0 auto;
  }
}

.mantenimiento_logo {
  width: 400px;
  margin-top: 90px;
  margin-bottom: 30px;
}

.mantenimiento_main_img {
  position: relative;
  top: -60px;
  width: 61.9%;
  @include breakpoint(large) {
    display: none;
  }
}

.mantenimiento_button_wrapper {
  width: 100%;
  height: 4rem;
  margin: 0.5rem 2rem;

  display: flex;
  justify-content: center;

  text-align: center;
  align-items: center;
  border-radius: 2em;

  background: #a8cc14;

  @include breakpoint(large) {
    height: 3.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  .mantenimiento_button {
    width: 100%;
    font-size: 1.5rem;
    font-weight: _font(regular);
    color: #0d374f;

    @include breakpoint(large) {
      font-size: 1.25rem;
    }

    @include breakpoint(medium) {
      font-weight: _font(light);
    }
  }
}

// mantenimiento module

.mantenimiento_modules_outer_container {
  background: url('../../images/mantenimiento_section_BG.png');
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
  height: 100vh;
}

.mantenimiento_modules_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: url('../../images/mantenimiento_module_BG.png');
  background-size: cover;
  overflow: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.mantenimiento_header {

  min-height: 4em;
  width: 100%;

  padding: 1em;
  margin-bottom: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2em;
  background: #a8cc14;

  .mantenimiento_title {
      font-size: 1.5rem;
      font-weight: _font(regular);
      color: #0d374f;
      text-align: center;
      
      @include breakpoint(large) {
          font-size: 1.25rem;
      }

      @include breakpoint(medium) {
          font-weight: _font(light);
      }

  }

}
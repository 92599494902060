.footer_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #d52b1d;
    color: #fff;
    padding: 20px 20px 20px 20px;
    min-height: 10%;

    a {
        color: #fff;
    }

}

.footer_vertical_line {
    margin: 0px 20px 0px 20px;
}

.footer_left_container {
    display: flex;
}

/* Login View */ 

.login {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  .login_content {

    position: relative;
    width: 100%;
    min-height: 100%;
    // min-height: calc(100% + #{_size(header_height_xlarge)});

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .login_background {
      
      position: absolute;
      left: 0;
      bottom: 0;
      height: 60%;

      z-index: -100;

      @include breakpoint(large) {
        height: 50%;
      }

      @include breakpoint(medium) {
        height: 40%;
      }

      @include breakpoint(small) {
        display: none;
      }

    }

    .login_form {

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;

      // background: white;
      border-radius: 0.5em;
      // box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.2);

      margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

      @include breakpoint(large) {
        margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
      }

      @include breakpoint(medium) {
        margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
      }

      @include breakpoint(small) {
        margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
      }

      @include breakpoint(xsmall) {
        margin-top: 1rem;
      }

      .login_form_paragraph {
        font-size: 16pt;
        color: _palette(accent_color);
        text-align: center;
        margin-bottom: 2rem;
      }

      // border: 1px solid lightskyblue;

      .login_form_image_wrapper {

        width: 160px;
        height: 160px;
        margin: 1em auto;
        border: 3px solid _palette(accent_color);
        border-radius: 50%;
        padding: 1.5em;

      }

      .login_form_title_wrapper {

        max-width: 480px;

        .login_form_title {
          font-size: 4rem;
          font-weight: _font(regular);
          line-height: 1em;
          text-align: center;
          color: _palette(accent_color);
        }
        .login_form_flavor {
          margin-top: 10px;
          text-align: center;
          font-size: 1rem;
        }

      }

      .login_form_inputs {

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 1em 2em;

        .login_form_input_wrapper {

          min-width: 280px;

          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          // margin: 1em;
          border: 2px solid _palette(accent_color);
          border-radius: 24px;
          background: white;
          width: 100%;
          // padding: 0.3em 1em;
          // width: 360px;
          // height: 48px;
          // font-size: 0.9em;

          &.margin_bottom {
            margin-bottom: 1em;
          }

          .login_form_icon {

            // width: 30px;
            height: 24px;
            width: 24px;
            margin: 0.5rem 0.75rem;
            
          }

          .login_form_input {

            // width: 240px;
            border: none;
            font-size: 1rem;
            font-weight: _font(light);
            margin-right: 0.5rem;
            flex-grow: 2;
            color: #666666;

            &.recover {
              margin: 0.2em 1em;
              text-align: center;
            }

            &:focus {
              outline: none;
            }

          }

        }

      }

      .login_form_enter {

        margin: 1em;
        width: 180px;
        // height: 2.5em;
        padding: 0 2em;
        border-radius: 1.25em;
        font-size: 1.2rem;
        line-height: 2em;
        background: _palette(primary_color);
        color: white;
        // font-weight: _font(medium);
        // text-transform: uppercase;
        border: 2px solid _palette(primary_color);

        &:hover {
          background: _palette(accent_color);
          border: 2px solid _palette(accent_color);
          cursor: pointer;
        }

        &:focus {
          border: 2px solid _palette(accent_color);
          outline: none;
        }

      }

      .login_form_response {
        width: 100%;
        text-align: center;
        color: #666666;
        font-size: 1rem;
        font-weight: _font(light);
        margin: 1rem;
      }

    }

    .login_recover {
      // width: 240px;
      color: _palette(accent_color);
      background: none;
      border: none;
      font-size: 1rem;
      line-height: 2em;
      padding: 0 1em;
      font-weight: _font(light);

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &:focus {
        // font-weight: bold;
        text-decoration: underline;
        outline: none;
      }

    }

    .login_reset_password_infography {
      // position: absolute;
      width: 300px;
      // right: 46px;
      // bottom: 52px;
      margin-top: 20px;
      text-align: center;
      font-size: 15px;
      // display: none;

      @include breakpoint(medium) {
        position: static;
        margin-top: 10px;
      }
    }

    // .section {

    //   display: flex;
    //   flex-direction: row nowrap;
    //   justify-content: flex-start;
    //   align-items: center;

    //   &.section_left {

    //     width: 70%;

    //     flex-grow: 2;

    //     background: lightskyblue;
    //     background: url('/images/background.jpg');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position: center;
    //     background-color: rgba(1, 75, 159, 0.7);
    //     background-blend-mode: multiply;

    //     @include breakpoint(medium) {
    //       display: none;
    //     }

    //     .container {

    //       max-width: 60%;
    //       margin-left: 10%;
    //       text-align: left;

    //       @include breakpoint(xlarge) {
    //         max-width: 70%;
    //         margin-left: 10%;
    //       }

    //       @include breakpoint(large) {
    //         max-width: 80%;
    //         margin-left: 10%;
    //       }

    //       .logo {
    //         width: 100%;
    //         max-width: 120px;
    //         margin-bottom: 2em;
    //       }

    //       .title {
    //         text-align: left;
    //         font-size: 1.2em;
    //         font-weight: normal;
    //         color: _palette(white);
    //       }

    //     }

    //   }

    //   &.section_right {

    //     width: 30%;

    //     background: _palette(white);
    //     min-width: 480px;

    //     @include breakpoint(medium) {

    //       width: 100%;
    //       min-width: initial;
    //     }

    //     .container {

    //       $login_form_margin: 3vh;

    //       width: 80%;
    //       max-width: 480px;
    //       margin-left: 10%;
    //       text-align: left;

    //       @include breakpoint(medium) {
    //         max-width: 380px;
    //         margin: 0 auto;
    //       }

    //       @include breakpoint(small) {
    //         width: 80%;
    //         margin: 0 auto;
    //       }

    //       .logo {
    //         width: 100%;
    //         margin-bottom: $login_form_margin;
    //         display: none;

    //         @include breakpoint(medium) {
    //           display: initial;
    //         }

    //       }

    //       .form_header, .form, .error_login {
    //         width: 100%;
    //         color: _palette(primary_color);
    //       }

    //       .form_header {
    //         font-size: 1.6rem;
    //         margin-bottom: $login_form_margin;

    //         @include breakpoint(medium) {
    //           display: none;
    //         }

    //       }

    //       .form {
    //         margin-bottom: $login_form_margin;

    //         .form_input {
    //           width: 100%;
    //           padding: 0.2em 0.4em;
    //           margin-bottom: $login_form_margin / 2;
    //           border: 1px solid _palette(primary_color);

    //           &:last-child {
    //             margin-bottom: 0;
    //           }

    //           &::placeholder {
    //             color: _palette(primary_dark);
    //           }

    //           &:focus {
    //             outline: none;
    //             border: 1px solid _palette(primary_dark);
    //           }

    //           &:focus::placeholder {
    //             color: _palette(primary_light);
    //           }

    //         }

    //       }

    //       .form_button {

    //         display: inline-block;

    //         height: 2em;

    //         padding: 0 2em;

    //         background: _palette(primary_color);
    //         color: _palette(white);
    //         border: 0;
    //         border-radius: _size(button_height_large) / 2;
    //         height: _size(button_height_large);

    //         &:focus {
    //           outline: none;
    //         }
    //       }

    //       .server_response {
    //         color: _palette(primary_dark);
    //         margin-top: $login_form_margin;
    //       }


    //     }

    //   }

    // }

  }

}


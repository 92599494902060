// .body{
//   overflow: auto;
//   height: 100%;
// }


.tower_modules{
  height: 100%;
  width: 100%;
  margin-top: 3.5rem;
  display: block;
  background-image: url("../../images/tower_main_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  background-attachment: scroll;
  overflow: scroll;
  position: relative;

  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // overflow: visible;

  .content_container{
    margin: auto;
    // border: 1px solid black;
    margin: 0;

    position: absolute;
    margin-top: 10rem;
    // top: 30vh;
    // -ms-transform: translateY(30vh);
    // transform: translateY(30vh);

    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    height: auto;
    width: auto;

    display: grid;

    .title_container{
      margin-bottom: 20px;
      margin-left: 10px;

      .title_span{
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 10px;

      }

      .span_beneficios{
        background-color: #f0b7b9;

      }

      .span_flota{
        background-color: #ffdd4a; 

      }

      .span_pagos{
        background-color: #c7ccd4;

      }
    }

    .resource_container{
      display: flex;
      // max-height: 500px;
      overflow: show;
      flex-direction: row;

      @include breakpoint(intermediate) {

        .tower_module{
          margin-top: 10px;
          margin-bottom: 10px;
        }

        flex-direction: column;
      }


      .tower_module{
        // width: 500px;
        // min-width: 500px;

        // height: auto;
        // min-height:500px;

        width: calc(100vw / 5  + 20px) ;
        height: calc(100vh / 5 + 25px) ;

        min-width: 399px;
        max-width: 1198.5px;

        min-height: 394px;
        max-height: 1182px;


        background-color: #c8def3;
        border-radius: 20px;
        margin-left: 10px;
        margin-right: 10px;

        display: flex;
        // justify-content: center;
        align-items:center;
        flex-direction: column;

        transition: 0.3s;


        .view_more{
          margin-top: 10px;
          margin-bottom: 5px;
        }


        .tower_module_content{

          position: relative;


          height: 95%;
          width: 95%;

          border-top-left-radius: 20px;
          border-top-right-radius: 20px;

          display: flex;
          flex-direction: column;
          align-items: left;

          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          transition: 0.5s;

          margin-top: 10px;

          .tower_flavor{
            width: 225px;
            height: 75px;
            min-height: 110px;
            border-radius: 0px 60px 60px 0px;
            margin-top: 25px;
            // margin-left: 12px;
            display: flex;
            align-items: center;
            flex-direction: row;

            .flavor_container{
              margin-left: 15px;

              .flavor_header{
                font-size: 20pt;
                font-weight: bold;
              }

            }
          }

          .flavor_beneficios {
            background-color: #f0b7b9;
          }

          .flavor_flota {
            background-color: #ffdd4a; 
          }

          .flavor_pagos {
            background-color: #c7ccd4;
          }

          .tower_items_container{
            width: 90%;
            margin-top: 50px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 100px;
            display: none;

            .tower_item{
              // border: 1px solid black;
              border-bottom: 1px solid white;
              margin-bottom: 10px;
            }

            .tower_item_link{
              color: white;

            }
          }

        }

        .tower_module_beneficios{
          background-image: url("../../images/tower_beneficios_bg.png");
        }

        .tower_module_flota{
          background-image: url("../../images/tower_flota_bg.png");

        }

        .tower_module_pagos{
          background-image: url("../../images/tower_pagos_bg.png");

        }

      }

      // HOVER

      @include breakpoint(intermediate) {
        .tower_module{
          max-height: unset;
          height: auto;
          background-color: #80bfe1;

          .tower_module_content{
            background-image: none;

            .tower_items_container{
              display: block;
            }
          }

          .view_more{
            display: none;
          }

        }
      }


      .tower_module:hover, tower_module:active{
        max-height: unset;
        height: auto;
        background-color: #80bfe1;

        .tower_module_content{
          background-image: none;

          .tower_items_container{
            display: block;
          }
        }

        .view_more{
          display: none;
        }

      }
    }
  }


}


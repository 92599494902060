.faqviewer_holder{
  width: 90vw;
  height: 80vh;
  border-radius: 15px;
  background-color: black;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  .faq_resource{
    width: inherit;
    height: inherit;
    border-radius: 15px;

  }
}


.fadeIn{
  animation: fadeIn .4s;
  -webkit-animation: fadeIn .4s;
  -moz-animation: fadeIn .4s;
  -o-animation: fadeIn .4s;
  -ms-animation: fadeIn .4s;
}

.fadeOut{
  animation: fadeOut .4s;
  -webkit-animation: fadeOut .4s;
  -moz-animation: fadeOut .4s;
  -o-animation: fadeOut .4s;
  -ms-animation: fadeOut .4s;

}

.feedback_modal{


  text-align: right;

  .close_feedback{
    margin-top: 30px;
    margin-bottom: 2px;
    border-radius:20px;
    border: 1px solid white;
    background-color: white;
  }

  .close_feedback:hover{
    cursor: pointer;
  }
}


.modal_pop{
  position: absolute;
  bottom: 0;
  right: 0;

  @include breakpoint(medium) {
    display: none;
  }

}

.modal_pop:hover{
  opacity: .5;

}

.modal_trigger{
  width: 400px;
}

.popup_modal_trigger{
  width: 400px;
  display: none;
}


.popup-overlay{
  background-color: #000;
  background-color: rgba(0,0,0,0.7);
}


@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

/* Ease in secondary */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

/* Settings View */ 

.settings {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  .settings_content {

    position: relative;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    z-index: 10;

    .settings_background {
      
      position: absolute;
      right: 10%;
      bottom: 0;
      height: 50%;

      z-index: -100;

      @include breakpoint(large) {
        height: 40%;
      }

      @include breakpoint(medium) {
        height: 30%;
      }

      @include breakpoint(small) {
        display: none;
      }

    }

    .settings_container {

      
      min-width: 480px;
      height: 100%;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;

      // flex-grow: 2;

      margin-bottom: 2rem;
      margin-top: calc((#{_size(tag_margin_xlarge)} * 2) + #{_size(tag_height_xlarge)});

      @include breakpoint(large) {
        margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
      }

      @include breakpoint(medium) {
        min-width: 420px;
        margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
      }

      @include breakpoint(small) {
        min-width: calc(100% - 2rem);
        margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
      }

      @include breakpoint(xsmall) {
        margin-top: 2rem;
      }

      .settings_header {

        width: 100%;
        height: 4rem;
        margin-bottom: 1em;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 2em;
        background: _palette(primary_color);

        @include breakpoint(large) {
          height: 3.5rem;
          margin-bottom: 0.5rem;
        }

        .settings_title {

          font-size: 1.5rem;
          font-weight: _font(regular);
          text-align: center;
          color: _palette(white);

          @include breakpoint(large) {
            font-size: 1.25rem;
          }

          @include breakpoint(medium) {
            font-weight: _font(light);
          }

        }

      }

      .settings_list {
        
        width: 80%;
        height: 100%;

        .settings_image_wrapper {

          position: relative;
          width: 180px;
          height: 180px;
          overflow: hidden;

          margin: 1rem auto;

          text-align: center;

          border-radius: 50%;
          border: 2px solid _palette(white);

          .settings_image {

            height: 100%;
            width: 100%;
            object-fit: cover;

          }

        }

        .settings_info {

          text-align: center;
          color: _palette(accent_color);
          text-shadow: -1px 0 _palette(white), 0 1px _palette(white), 1px 0 _palette(white), 0 -1px _palette(white);

        }

        .settings_item {

          padding: 0.5em;
          margin: 1em 0;
          border-radius: 2em;
          border: 1px solid _palette(accent_color);
          background: _palette(white);
          text-align: center;

          &.logout {
            background: _palette(accent_color);
          }

          .settings_item_link {

            color: _palette(accent_color);
            font-weight: _font(light);

            &.logout {
              color: _palette(white);
            }

          }

        }

      }

    }

  }

}


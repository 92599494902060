/* Typography */

@font-face {
  font-family: "Churchward";
  src: url("../fonts/churchward_lorina_black.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Churchward";
  src: url("../fonts/churchward_lorina_bold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Churchward";
  src: url("../fonts/churchward_lorina_regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Churchward";
  src: url("../fonts/churchward_lorina_light.otf");
  font-weight: 200;
  font-style: normal;
}

* {
	font-family: _font(family);
}

body, input, textarea, select {
	font-family: _font(family);
	font-weight: _font(regular);
	font-size: 16pt;
	line-height: 1.75em;
	color: _palette(fg);
	letter-spacing: 0.025em;

	@include breakpoint(xxlarge) {
		font-size: 15pt;
	}

	@include breakpoint(xlarge) {
		font-size: 14pt;
	}

	@include breakpoint(large) {
		font-size: 12pt;
	}

	@include breakpoint(medium) {
		font-size: 12pt;
	}

	@include breakpoint(small) {
		font-size: 11pt;
		line-height: 1.5em;
	}

	@include breakpoint(xsmall) {
		font-size: 10pt;
		line-height: 1.5em;
	}
	
}

h1, h2, h3, h4, h5, h6 {
	font-weight: _font(bold);
	color: inherit;

	a {
		color: inherit;
		text-decoration: none !important;
	}

}

h2 {
	font-size: 18pt;

	@include breakpoint(large) {
		font-size: 16pt;
	}

	@include breakpoint(small) {
		line-height: 1.2em;
	}

}

h4.card_title, h4.resource_card_title {
	font-size: 1rem;
	line-height: initial;
}

p, ul, ol, dl, table {
	font-weight: _font(regular);
}

p.card_description, p.card_meta, p.resource_card_description, p.resource_card_meta {
	font-size: 0.7rem;
	letter-spacing: initial;
	line-height: initial;
	margin-top: 0.4rem;
}

p.card_description, p.resource_card_description {
	color: _palette(black);
}

p.card_meta, p.resource_card_meta {
	color: _palette(primary_dark);
}

strong, b {
	font-weight: _font(bold);
	color: inherit;
}

em, i {
	font-style: italic;
}

a {
	@include vendor('transition', 'color #{_duration(transition)} ease-in-out');
	color: _palette(primary_color);
	text-decoration: none !important;

	&.link {
		font-size: 1rem;
		font-weight: _font(bold);
	}
}

button {
	font-family: _font(family);
}

sub {
	position: relative;
	top: 0.5em;
	font-size: 0.8em;
}

sup {
	position: relative;
	top: -0.5em;
	font-size: 0.8em;
}

hr {
	border: 0;
	border-top: solid 1px _palette(border);
}

blockquote {
	border-left: solid 0.5em _palette(border);
	padding: 1em 0 1em 2em;
	font-style: italic;
}

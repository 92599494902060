
/* Tag Component */ 

  .tag {

    position: absolute;
    top: 0;
    right: 0;
    height: _size(tag_height_xlarge);
    margin: _size(tag_margin_xlarge) 0;
    width: 320px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.5em 0.75em;
    border-radius: 2em 0 0 2em;

    // overflow: hidden;
    z-index: -10;

    @include breakpoint(large) {
      width: 280px;
      margin: _size(tag_margin_large) 0;
      height: _size(tag_height_large);
    }

    @include breakpoint(medium) {
      width: 240px;
      margin: _size(tag_margin_medium) 0;
      height: _size(tag_height_medium);
    }

    @include breakpoint(small) {
      width: 200px;
      margin: _size(tag_margin_small) 0;
      height: _size(tag_height_small);
      // display: none;
    }

    @include breakpoint(xsmall) {
      display: none;
      width: 180px;
      margin: _size(tag_margin_xsmall) 0;
      height: _size(tag_height_xsmall);
    }

    // &.header {
    //   margin-top: 6em;
    // }

    &.etraining {

      background: none !important;
      height: _size(tag_height_xlarge);
      margin: _size(tag_margin_xlarge);
      width: auto;
      height: 120px;

      padding: 0;
      border-radius: 0;
      box-shadow: none;

      // overflow: hidden;
      z-index: -10;

      @include breakpoint(medium) {
        height: 100px;
      }

      @include breakpoint(small) {
        display: none;
      }

    }

    &.primary {

      background: _palette(primary_color);

    }

    &.white {

      background: _palette(white);
      padding: 0.5em 1.5em;

    }

    .tag_label {
      color: white;
      font-size: 1.5em;
      font-weight: bold;
    }

    .tag_image {

      height: 100%;
      max-width: 100%;

    }

  }
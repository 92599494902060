.modal {
// 
  position: absolute;
  max-height: calc(100vh - 140px);
  width: 1200px;
  font-size: 12px;
  margin-top: 40px;

  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  display: flex;
  @include breakpoint(large) {
      width: 90vw;
    }
  }
.modal > .content {
    margin: 0 auto;
    width: inherit;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
      z-index: 1;
    }
  }

.modal > .content > .close {
    cursor: pointer;
    position: relative;
    // display: block;
    padding: 2px 5px;
    line-height: 20px;

    left: 52%;
    top: -7px;
    
    font-size: 27px;
    color: #d52b1e;
    background: #ffffff;
    border-radius: 18px;
    // border: 1px solid #cfcece;
    border: none;
    outline: 0;
    z-index: 2;

    @include breakpoint(large) {
      left: 48%;
      top: -20px; 
    }
  }



/* 404 View */ 

.not_found {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .not_found_content {

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    background: _palette(white);

    .not_found_label {
      font-size: 2em;
      font-weight: _font(regular);
      letter-spacing: 0.05em;
      color: _palette(accent_color);

      @include breakpoint(medium) {
        font-size: 1.75em;
      }

      @include breakpoint(small) {
        font-size: 1.5em;
      }

    }

  }

}


$ticket-turquoise: #00859b;
$diploma-red: #F0002B;
$diploma-red-hober: #BD0022;


.ticket_car_container {
  display: flex;
  // justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: url("../../images/ticket_car_section_BG.png");
  background-size: cover;
  background-repeat: repeat-x;
}

.ticket_car_form_container {
  position: relative;
  // left: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15vw;
  margin-bottom: 6rem;
  @include breakpoint(large) {
    margin: 0 auto;
  }
}

.ticket_car_logo {
  width: 350px;
  margin-bottom: 30px;
}

.ticket_car_main_img {
  position: relative;
  right: 0;
  bottom: 0;
  width: 55%;
  overflow: visible;
  margin-right: -10vw;
  @include breakpoint(large) {
    display: none;
  }
}

.ticket_car_button_wrapper {
  width: 100%;
  height: 4rem;
  margin: 0.5rem 2rem;

  display: flex;
  justify-content: center;

  text-align: center;
  align-items: center;
  border-radius: 2em;

  background: $ticket-turquoise;

  @include breakpoint(large) {
    height: 3.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  .ticket_car_button {
    width: 100%;
    font-size: 1.5rem;
    font-weight: _font(regular);
    color: _palette(white);

    @include breakpoint(large) {
      font-size: 1.25rem;
    }

    @include breakpoint(medium) {
      font-weight: _font(light);
    }
  }
}

.diploma_button_wrapper {
  width: 100%;
  height: 4rem;

  display: flex;
  justify-content: center;

  text-align: center;
  align-items: center;
  border-radius: 2em;
  color: _palette(white);

  background: $diploma-red;

  @include breakpoint(large) {
    height: 3.5rem;
  }

  &:hover {
    cursor: pointer;
    background: $diploma-red-hober;
  }
}

.li_buttom {
  margin-top: 30px;
}

//Ticket car modules//
.ticket_car_modules_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 90px;
  background: url("../../images/ticket_car_module_BG.png");
  background-size: cover;
  overflow: auto;
}

.ticket_car_header {
  min-height: 4em;
  width: 100%;

  padding: 1em;
  margin-bottom: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2em;
  background: $ticket-turquoise;

  .ticket_car_title {
    font-size: 1.5rem;
    font-weight: _font(regular);
    color: _palette(white);
    text-align: center;

    @include breakpoint(large) {
      font-size: 1.25rem;
    }

    @include breakpoint(medium) {
      font-weight: _font(light);
    }
  }
}

.modules_list {
  width: 100%;
  height: 100%;

  .modules_item {
    padding: 0.5em 1em;
    border-bottom: 1px solid $ticket-turquoise;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .modules_item_link {
      color: $ticket-turquoise;
      font-weight: _font(light);
      text-align: left;
      flex-grow: 2;
      margin-right: 1em;
    }

    .modules_item_check {
      width: 24px;
      height: 24px;
    }
  }
}


/* Modules View */ 

.modules {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  .modules_content {

    position: relative;
    width: 100%;
    height: 100%;

    // display: flex;
    // flex-flow: row;
    // justify-content: center;
    // align-items: center;

    z-index: 10;

    background: _palette(white);

    .modules_background {
      
      position: absolute;
      left: 0;
      bottom: 0;
      max-width: calc(50vw - 240px);
      max-height: 480px;
      // max-width: calc(100% - 240px);

      @include breakpoint(medium) {
        display: none;
      }

      &.vertical {

        height: 60%;
        width: auto;

        @include breakpoint(large) {
          height: 45%;
        }

        @include breakpoint(medium) {
          height: 35%;
        }
        
      }

    }

    .payload_wrapper{
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      .modules_splash_container{
        position: relative;
        width: 50%;
        height: 100%;

        @media screen and (max-width: 1330px){
            display: none;
        }


        // height: 100%;
        .modules_splash_wrapper{
          // margin-bottom: -20px;
          position: absolute;
          bottom:0;
          right: 0;
          width: auto;
          height: 100%;

          .modules_splash{
            float: right;
            height: 100%;
            // width: 100%;
            margin-top: 4vh;
            // margin-bottom: -10px;
            max-width: 1000px;
          }
        }
      }

      .content_payload_wrapper{
        width:50%;
        // min-height: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1330px){
            width: 100%;
            margin-left: 30px;
            margin-right: 30px;
        }


        .modules_container_wrapper{

          width: 100%;
          display: flex;
          height: 100%;
          margin-top: 100px;
          justify-content: center;

          .modules_container {

            // min-width: 480px;
            max-width: 500px;
            // height: 100%;
            // margin-left: 80px;
            // margin-right: 30px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: center;

            flex-grow: 2;

            // margin-bottom: 2rem;
            // margin-top: -20%;

            // @include breakpoint(large) {
            //   margin-top: calc((#{_size(tag_margin_large)} * 2) + #{_size(tag_height_large)});
            // }

            // @include breakpoint(medium) {
            //   min-width: 420px;
            //   margin-top: calc((#{_size(tag_margin_medium)} * 2) + #{_size(tag_height_medium)});
            // }

            // @include breakpoint(small) {
            //   min-width: calc(100% - 2rem);
            //   margin-top: calc((#{_size(tag_margin_small)} * 2) + #{_size(tag_height_small)});
            // }

            // @include breakpoint(xsmall) {
            //   margin-top: 1rem;
            // }

            .modules_header_logo_wrapper{
              width: 50%;
              margin-bottom: 50px;

              img{
                width: 100%;
                height: 100%;
              }
            }

            .modules_header {

              min-height: 4em;
              width: 100%;

              padding: 1em;
              margin-bottom: 1em;

              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 2em;
              background: _palette(primary_color);

              .modules_title {

                font-size: 1.5rem;
                font-weight: _font(regular);
                color: _palette(white);
                text-align: center;

                @include breakpoint(large) {
                  font-size: 1.25rem;
                }

                @include breakpoint(medium) {
                  font-weight: _font(light);
                }

              }

            }

            .modules_list {
              
              width: 100%;
              height: 100%;

              .modules_item {

                padding: 0.5em 1em;
                border-bottom: 1px solid _palette(accent_color);

                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;

                .modules_item_link {

                  color: _palette(accent_color);
                  font-weight: _font(light);
                  text-align: left;
                  flex-grow: 2;
                  margin-right: 1em;

                }

                .modules_item_check {

                  width: 24px;
                  height: 24px;

                }

              }

            }

          }
        }
      }


    }

  }

}

.modules_empresarial{
  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }
  background-image: url('../../assets/Assets_Empresarial/back_2_01.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 115% 100%;
  .module_content{
    background-image: url('../../assets/Assets_Empresarial/back_2_02.png');
    background-repeat: no-repeat;
    background-size: 90% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5% 30%;

    .logo_empresarial{
      width: 45%;
      height: auto;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
    .title_card_mng{
      width: 70%;
      height: auto;
      margin-bottom: 2rem;
    }
    .button_card_mng{
      width: 70%;
      height: auto;
      margin-top: 5%;
    }
  }
}


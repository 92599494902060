
/* Welcome View */ 

.welcome {

  height: calc(100% - #{_size(header_height_xlarge)});
  margin: _size(header_height_xlarge) auto 0 auto;

  @include breakpoint(large) {
    margin-top: _size(header_height_large);
    height: calc(100% - #{_size(header_height_large)});
  }

  @include breakpoint(medium) {
    margin-top: _size(header_height_medium);
    height: calc(100% - #{_size(header_height_medium)});
  }

  @include breakpoint(small) {
    margin-top: _size(header_height_small);
    height: calc(100% - #{_size(header_height_small)});
  }

  .welcome_content {

    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    background: black;

    .welcome_container {

      width: 100%;
      height: 100%;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;

      .welcome_video {

        width: 100%;
        height: 100%;

      }

    }

  }

}


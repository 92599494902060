/* Header */

.header {
    position: fixed;
    width: 100%;
    height: _size(header_height_xlarge);
    background: _palette(white);
    box-shadow: 0 1px 6px 1px _palette(shadow_dark);
    z-index: 1000;
    @include breakpoint(large) {
        height: _size(header_height_large);
    }
    @include breakpoint(medium) {
        height: _size(header_height_medium);
    }
    @include breakpoint(small) {
        height: _size(header_height_small);
    }
    .content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        // justify-content: space-between;
        // align-items: center;
        .section {
            width: 50%;
            height: 100%;
            margin: 0;
            padding: 0;
            // border: 1px solid yellow;
        }
        .section_left {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            .logo_wrapper {
                height: 100%;
                // width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                // border: 1px solid blue;
                &:focus {
                    outline: none;
                }
                .logo {
                    height: 75%;
                    cursor: pointer;
                    margin: 0 0.5rem;
                    // border: 1px solid white;
                }
            }
        }
        .section_right {
            height: 100%;
            // width: _size(header_height_large);
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            // @include breakpoint(medium) {
            // 	width: _size(header_height_medium);
            // }
            // @include breakpoint(small) {
            // 	width: _size(header_height_small);
            // }
            // border: 1px solid blue;
            .settings_wrapper {
                height: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                .settings_logo {
                    height: 60%;
                    cursor: pointer;
                    margin: 1rem;
                    padding: 0;
                }
            }
            .welcome_wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                cursor: pointer;
                .welcome_label {
                    // height: 60%;
                    margin: 0 1rem;
                    padding: 0;
                    font-size: 1.4rem;
                    line-height: 1em;
                    color: _palette(black);
                    font-weight: _font(light);
                }
            }
        }
    }
}
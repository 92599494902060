$ecovale-pink: #ff5978;


.ecovale_container {
    display: flex;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: url('../../images/ecovale_section_BG.png');
    background-size: cover;
    background-repeat: repeat-x;
}

.ecovale_form_container {
    position:relative;
    // left: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15vw;
    margin-bottom: 6rem;
    @include breakpoint(large) {
        margin: 0 auto;
    }

}

.ecovale_logo {
    width: 350px;
    margin-bottom: 30px;
    margin-top: 50px;
}

.ecovale_main_img {
    position: relative;
    right: 10;
    bottom: 0;
    width: 55%;
    overflow: visible;
    margin-right: 0vw;

    @include breakpoint(large) {
        display: none;
    }
}

.ecovale_button_wrapper {
    width: 100%;
    height: 4rem;
    margin: 0.5rem 2rem;

    display: flex;
    justify-content: center;

    text-align: center;
    align-items: center;
    border-radius: 2em;

    background: $ecovale-pink;

    @include breakpoint(large) {
        height: 3.5rem;
    }

    &:hover {
        cursor: pointer;
    }

    .ecovale_button {
        width: 100%;
        font-size: 1.5rem;
        font-weight: _font(regular);
        color: _palette(white);

        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }
    }

    .ecovale_button_migrate{
        width: 100%;
        font-size: 1.5rem;
        font-weight: _font(regular);
        color: _palette(white);
        background: none;
        border: none;

        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }        
    }
    .ecovale_button_migrate:hover{
        cursor: pointer;
    }

}

//Ticket car modules//
.ecovale_modules_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 90px;
    background: url('../../images/ecovale_module_BG.png');
    background-size: cover;
    overflow: auto;
}

.ecovale_faq{
    background: none;
}

.modules_container{
    min-width: 400px;
    @include breakpoint(medium) {
        min-width: 80%;
    }
}

.ecovale_header {

    min-height: 4em;
    width: 100%;

    padding: 1em;
    margin-bottom: 1em;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2em;
    background: $ecovale-pink;

    .ecovale_title {
        font-size: 1.5rem;
        font-weight: _font(regular);
        color: _palette(white);
        text-align: center;
        
        @include breakpoint(large) {
            font-size: 1.25rem;
        }

        @include breakpoint(medium) {
            font-weight: _font(light);
        }

    }

}

.modules_list {
    width: 100%;
    height: 100%;

    .modules_item {
        padding: 0.5em 1em;
        border-bottom: 1px solid #162056;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .modules_item_link {
            color: #162056;
            font-weight: _font(light);
            text-align: left;
            flex-grow: 2;
            margin-right: 1em;
        }

        .modules_item_button{
            color: #162056;
            font-weight: _font(light);
            text-align: left;
            flex-grow: 2;
            margin-right: 1em;
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline: none;
        }

        .modules_item_check {
            width: 24px;
            height: 24px;
        }
    }
}